// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-cms-index-js": () => import("./../../../src/pages/cms/index.js" /* webpackChunkName: "component---src-pages-cms-index-js" */),
  "component---src-pages-contact-buying-js": () => import("./../../../src/pages/contact/buying.js" /* webpackChunkName: "component---src-pages-contact-buying-js" */),
  "component---src-pages-contact-contact-featured-img-js": () => import("./../../../src/pages/contact/contactFeaturedImg.js" /* webpackChunkName: "component---src-pages-contact-contact-featured-img-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-info-js": () => import("./../../../src/pages/contact/info.js" /* webpackChunkName: "component---src-pages-contact-info-js" */),
  "component---src-pages-contact-selling-js": () => import("./../../../src/pages/contact/selling.js" /* webpackChunkName: "component---src-pages-contact-selling-js" */),
  "component---src-pages-contact-showing-js": () => import("./../../../src/pages/contact/showing.js" /* webpackChunkName: "component---src-pages-contact-showing-js" */),
  "component---src-pages-contact-submit-button-js": () => import("./../../../src/pages/contact/submitButton.js" /* webpackChunkName: "component---src-pages-contact-submit-button-js" */),
  "component---src-pages-contact-testimonial-js": () => import("./../../../src/pages/contact/testimonial.js" /* webpackChunkName: "component---src-pages-contact-testimonial-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-resources-gallery-js": () => import("./../../../src/pages/resources/gallery.js" /* webpackChunkName: "component---src-pages-resources-gallery-js" */),
  "component---src-pages-resources-index-js": () => import("./../../../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-services-calinks-js": () => import("./../../../src/pages/services/calinks.js" /* webpackChunkName: "component---src-pages-services-calinks-js" */),
  "component---src-pages-services-custom-haylage-silage-hauling-js": () => import("./../../../src/pages/services/custom-haylage-silage-hauling.js" /* webpackChunkName: "component---src-pages-services-custom-haylage-silage-hauling-js" */),
  "component---src-pages-services-custom-manure-hauling-js": () => import("./../../../src/pages/services/custom-manure-hauling.js" /* webpackChunkName: "component---src-pages-services-custom-manure-hauling-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-testimonials-index-js": () => import("./../../../src/pages/testimonials/index.js" /* webpackChunkName: "component---src-pages-testimonials-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

